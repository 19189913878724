import { useSelector } from "react-redux";

import SummaryComponent from "../../components/Summary";

const Summary = () => {
    const hasCoffee = useSelector((state) => state.room.hasCoffee);
    const show = useSelector((state) => state.room.show);
    const cards = useSelector((state) => state.room.cards);
    const summary = useSelector((state) => state.room.summary);
    return (
      <SummaryComponent hasCoffee={hasCoffee} show={show} summary={summary} cards={cards} /> 
    );
  }
  
  export default Summary;
  