import Typography from '@mui/material/Typography';
import UserVote from "../UserVote";
import "./UsersVotes.css";

const UsersVotes = ({usersVotes = {}, show = false}) => {
    let keys = Object.keys(usersVotes);
    keys = keys.sort((a,b) => {
        const userVoteA = usersVotes[a];
        const userVoteB = usersVotes[b];
        if (userVoteA.viewer && !userVoteB.viewer) return 1;
        if (!userVoteA.viewer && userVoteB.viewer) return -1;
        if (!userVoteA.voted && userVoteB.voted) return 1;
        if (!userVoteB.voted && userVoteA.voted) return -1;
        if (show && userVoteA.vote !== userVoteB.vote) return userVoteA.vote - userVoteB.vote;
        return usersVotes[a].name.localeCompare(usersVotes[b].name)
    })
    return (
        <div className="users">
            <Typography className="summary-title" variant="h2" component="h2">Votes</Typography>
            {
                keys.map(
                    (key, index) => {
                        const userVote = usersVotes[key];
                        return <UserVote 
                                    key={index} 
                                    name={userVote.name} 
                                    vote={userVote.vote} 
                                    voted={userVote.voted} 
                                    show={show} 
                                    type={userVote.type} 
                                    viewer={userVote.viewer}
                                />
                    }
                )
            }
        </div>
    );
  }
  
  export default UsersVotes;
  