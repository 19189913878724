import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Room from './containers/Room';
import reportWebVitals from './reportWebVitals';
import store from './store'
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewRoom from './containers/NewRoom';
import Privacy from './containers/Privacy';
import NewGuest from './containers/NewGuest';
import ContactUs from './containers/ContactUs';
import About from './containers/About';
import AdVertical from './components/AdVertical';

window.dataLayer = window.dataLayer || [];
export function gtag() { window.dataLayer.push(arguments); }
gtag('js', new Date());

gtag('config', 'G-4GJ4MQSN1E');
gtag('config', 'AW-1012272179');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <div className="ads-vertical">
          <AdVertical />
        </div>
        <BrowserRouter>
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/new-user" element={<NewGuest />} />
            <Route path="/room/:roomId" element={<Room />} />
            <Route path="/" element={<NewRoom />} />
          </Routes>  
        </BrowserRouter>
        <div className="ads-horizontal"></div>
        <div className="ads-vertical">
          <AdVertical />
        </div>
    </Provider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
