import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { gtag } from "../..";
import { useEffect } from "react";
import "./Privacy.css";

const Privacy = () => {
    useEffect(() => {
        gtag('event', 'page_view', {
            page_title: 'Privacy',
            page_path: '/privacy'
        });
    });
    return (
        <div className="about">
            <Typography component="h1" variant="h1">
                Terms and conditions
            </Typography>
            <Card>
                <CardContent className='summary-content'>
                    <Typography component="p" variant="p">
                        These terms and conditions (&#8220;Agreement&#8221;) set forth the general terms and conditions of your use of the <a href="https://poker-scrum.com">poker-scrum.com</a> website (&#8220;Website&#8221;), &#8220;Poker Scrum&#8221; mobile application (&#8220;Mobile Application&#8221;) and any of their related products and services (collectively, &#8220;Services&#8221;). This Agreement is legally binding between you (&#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221;) and this Website operator and Mobile Application developer (&#8220;Operator&#8221;, &#8220;we&#8221;, &#8220;us&#8221; or &#8220;our&#8221;). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms &#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221; shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Services. By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Services.
                    </Typography>   
                    <Typography component="h3" variant="h3">
                        Table of contents
                    </Typography>   
                    <ol className="wpembed-index"><li><a href="#advertisements">Advertisements</a></li><li><a href="#links-to-other-resources">Links to other resources</a></li><li><a href="#changes-and-amendments">Changes and amendments</a></li><li><a href="#acceptance-of-these-terms">Acceptance of these terms</a></li><li><a href="#contacting-us">Contacting us</a></li></ol>
                    <Typography component="h2" variant="h2">
                        Advertisements
                    </Typography>   
                    <Typography component="p" variant="p">
                        During your use of the Services, you may enter into correspondence with or participate in promotions of advertisers or sponsors showing their goods or services through the Services. Any such activity, and any terms, conditions, warranties or representations associated with such activity, is solely between you and the applicable third party. We shall have no liability, obligation or responsibility for any such correspondence, purchase or promotion between you and any such third party.
                    </Typography>   
                    <Typography component="h2" variant="h2">
                        Links to other resources
                    </Typography>   
                    <Typography component="p" variant="p">
                        Although the Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Services. Your linking to any other off-site resources is at your own risk.
                    </Typography>   
                    <Typography component="h2" variant="h2">
                        Changes and amendments
                    </Typography>   
                    <Typography component="p" variant="p">
                        We reserve the right to modify this Agreement or its terms related to the Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.
                    </Typography>   
                    <Typography component="p" variant="p">
                        An updated version of this Agreement will be effective immediately upon the posting of the revised Agreement unless otherwise specified. Your continued use of the Services after the effective date of the revised Agreement (or such other act specified at that time) will constitute your consent to those changes.
                    </Typography>    
                    <Typography component="h2" variant="h2">
                        Acceptance of these terms
                    </Typography>   
                    <Typography component="p" variant="p">
                        You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Services.
                    </Typography>       
                    <Typography component="h2" variant="h2">
                        Contacting us
                    </Typography>   
                    <Typography component="p" variant="p">
                        <a href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;poker&#115;&#99;r&#117;m&#56;&#55;&#64;g&#109;a&#105;&#108;&#46;co&#109;">po&#107;&#101;rs&#99;&#114;um&#56;&#55;&#64;g&#109;&#97;&#105;&#108;&#46;com</a>
                    </Typography>      
                    <Typography component="p" variant="p">
                        This document was last updated on December 24, 2022
                    </Typography> 
                    <p className="madewith"><a href="https://www.websitepolicies.com/?via=madewithbadge" target="_blank" rel="noreferrer"><img width="200" height="25" alt="Made with WebsitePolicies" src="https://cdn.websitepolicies.io/img/badge.png" srcSet="https://cdn.websitepolicies.io/img/badge_2x.png 2x" /></a></p>  
                </CardContent>
            </Card>  
        </div>
    );
}

export default Privacy;
