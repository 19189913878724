import Card from "../Card";
import "./Cards.css";

const Cards = ({cards = [], onClick = () => {}, selectedCard="", youAreAViewer}) => {
    return (
        <div className="cards">
            {
                cards.map(
                    (card, index) => 
                    <Card 
                        youAreAViewer={youAreAViewer}
                        key={index} 
                        value={card.value} 
                        selected={card.value === selectedCard} 
                        onClick={onClick} 
                        type={card.type} 
                        tooltip={card.tooltip}
                    />
                )
            }
        </div>
    );
  }
  
  export default Cards;
  