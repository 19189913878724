import WebSocket from 'isomorphic-ws';
import { updateRoom, toggleLoading, lostConnection } from "../features/roomReducer";
import axios from 'axios';

let WSService = null;

class WebSocketService {

  constructor() {    
    this.websocket = null;
    this.messageListeners = [];
    this.isOpen = false;
  }

  /**
   *  Set up WebSocket connection for a new user and
   *  basic listeners to handle events
   */
  initSocket = ({name, roomId, dispatch}) => {
    this.websocket = new WebSocket(`wss://6y0k1ep9ze.execute-api.sa-east-1.amazonaws.com/prod?name=${name}&roomId=${roomId}`);
    this.websocket.onopen = this.onConnOpen;
    this.websocket.onmessage = this.onMessage;
    this.websocket.onclose = this.onConnClose;
    this.websocket.onerror = this.onError;
    this.dispatch = dispatch;
    this.roomId = roomId;
    this.name = name;
    window.sendMessage = this.sendMessage;
  }

  onError = (err) => {
    console.log(err)
  }

  ping = () => {
    this.sendMessage('ping', {});
    this.tm = setTimeout(function () {
      this.dispatch(lostConnection());
    }, 5000);
    console.log("ping");
  }

  pong = () => {
    clearTimeout(this.tm);
    console.log("pong");
  }

  /**
   *  Show connection status to us in the log
   */
  onConnOpen = (e) => {
    this.isOpen = true;
    console.log('Websocket connected!');   
    const getGuests = async () => {
      debugger
      const response = await axios.get(`https://le17na59s3.execute-api.sa-east-1.amazonaws.com/prod/?roomId=${this.roomId}`)
      this.dispatch(updateRoom(response.data.room));
      this.dispatch(toggleLoading());
    }
    getGuests();
    setInterval(this.ping, 30000);
  }

  /**
   *  Log lost connection for now
   */
  onConnClose = () => {
    console.log('Websocket closed!');
    this.dispatch(lostConnection());
  }

  /**
   *  Used by application to send message to the WebSocket API Gateway
   *  @param routeKey The route key for WebSocket API Gateway
   *  @param message String message
   *  message {
   *    room,
   *    type,
   *    msg,
   *    username,
   *    for
   *  }
   */
  sendMessage = (routeKey, message) => {    
    if (this.websocket && this.isOpen){
      this.websocket.send(JSON.stringify({
        action: routeKey,
        message: JSON.stringify(message)
      }));
    } else {      
      console.log(`Websocket connection not found!!`);
      this.dispatch(lostConnection());
    }    
  }

  /**
   *  Used by application to register different listeners for 
   *  different message types
   *  @param room Room name
   *  @param type Message type ['all', 'pm', 'userlist', 'useradd']
   *  @param listener Function to handle message type
   */
  addMessageListener = (room, type, listener) => {    
    if (!type || !room || typeof listener !== 'function') {
      return;
    }
    this.messageListeners.push({
      room,
      type,
      listener
    });
  }

  /**
   * Handler that receives the actual messages from the WebSocket API
   * For now it simply returns the parsed message body to the appropriate
   * registered handler
   * @param data Message body received from WebSocket 
   */
  onMessage = (data) => {
    if (data) {
      if (data.data === 'pong') {
        this.pong();
        return;
      }
      const typeListener = this.messageListeners.find(listener => listener.type === data.type);
      
      const guests = JSON.parse(data.data);    
      console.log("guests", guests);
      this.dispatch(updateRoom(guests));
      if (typeListener && typeof typeListener.listener === "function") {      
        typeListener.listener(guests);
      } else {
        console.log('No handler found for message type');
      }
    }
  }

  static initWSService({roomId = "", name = "", dispatch}) {    
    if (!WSService) {      
      WSService = new WebSocketService();
      WSService.initSocket({roomId, name, dispatch});
      return WSService;
    }
    
    return WSService;
  }

}

export const getWSService = WebSocketService.initWSService;