import React from 'react';
import Typography from '@mui/material/Typography';

import NameForm from '../NameForm';

import "./NewGuest.css";

const NewGuest = ({onSubmit, verb, buttonDisabled}) => {
    return (
      <div className="new-guest">
        <Typography component="h1" variant="h1">Welcome to<br/>Scrum Poker</Typography>
        <Typography component="h2" variant="h2">Set your name and enjoy your planning</Typography>
        <NameForm onSubmit={onSubmit} verb={verb} buttonDisabled={buttonDisabled} />
      </div>
    );
}
  
export default NewGuest;
  