import { useEffect } from "react";

const AdVertical = () => {
  useEffect (() => {
    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});

    }, 500);
  }, [])

  return (
    <div className='ad'>
      <ins className='adsbygoogle'
        style={{ display: 'inline-block', width: '300px', height: '600px' }}
        data-ad-client='ca-pub-6893697054982551'
        data-ad-slot='1668082539'
        data-ad-format='auto' />
    </div>
  );
}

export default AdVertical;