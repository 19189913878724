import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Loading from '../../components/Loading';
import LostConnection from '../../components/LostConnection';

import Actions from "../Actions";
import Cards from "../Cards";
import UsersVotes from "../UsersVotes";
import Summary from '../Summary';
import { setRoom } from "../../features/roomReducer";
import { getWSService } from '../../services/WebSocketService';
import { gtag } from '../..';

import "./App.css";


const App = () => {
  const { roomId } = useParams();
  const name = useSelector((state) => state.room.name);
  const loading = useSelector((state) => state.room.loading);
  const lostConnection = useSelector((state) => state.room.lostConnection);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    gtag('event', 'page_view', {
        page_title: 'Room',
        page_path: '/room'
    });
  });
  
  useEffect(() => {
    dispatch(setRoom(roomId));
    if (!roomId || roomId === "undefined" || roomId === undefined) navigate("/");
    else if (!name) navigate("/new-user");
    else getWSService({ roomId, name, dispatch });
  }, [name, roomId, dispatch, navigate])

  if (loading) return <Loading />

  if (lostConnection) return <LostConnection />

  return (
    <div className="app">
        <div className="room-info">
            <Actions />
            <div className='room-group'>
              <UsersVotes />
              <Summary />
            </div>
        </div>
        <div className="my-cards">
            <Cards />
        </div>
    </div>
  );
}

export default App;
