import CardUi from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';

import ICONS, { ICON_TYPE } from '../../utils/icons';
import "./Card.css"

const Card = ({value = "?", selected = false, onClick = () => {}, type = "", youAreAViewer, tooltip}) => {
    const handleOnClick = () => onClick({ type, vote: value })
    return (
      <Tooltip placement="top" title={tooltip}>
        <CardUi 
          className={`card ${selected && " card-selected "} ${youAreAViewer && " viewer "}`} 
          onClick={handleOnClick}
        >
          <CardContent> 
            {
              type === ICON_TYPE ? ICONS[value] : value
            }
          </CardContent> 
        </CardUi>
      </Tooltip>
    );
  }
  
  export default Card;
  