import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import NameForm from "../../components/NameForm";
import Instructions from "../../components/Instructions";
import { setName } from "../../features/roomReducer";
import { gtag } from "../..";
import "./NewRoom.css";

const NewRoom = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [count, setCount] = useState(null);
    const [buttonDisabled, setButtonDisable] = useState(false);

    async function OnSubmit(name) {
        gtag('event', 'conversion', {
            'event_category': 'create_new_room',
            'event_label': 'new_room'
        });
        setButtonDisable(true);
        dispatch(setName(name));
        const { data: {roomId} } = await axios.post('https://yfd6qwpwx6.execute-api.sa-east-1.amazonaws.com/prod');
        navigate(`/room/${roomId}`);
    }

    useEffect(() => {
        gtag('event', 'page_view', {
            page_title: 'Home',
            page_path: '/'
        });
    });

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await axios.get('https://iz4n02uaki.execute-api.sa-east-1.amazonaws.com/prod/?counter_key=poker-scrum')
            return data;
        }
        fetchData()
        .then(data => setCount(data.count));
    }, [count]);

    return (
        <div className="new-room">
            <div className='left'>
                <Typography component="h1" variant="h1">Your planning<br/>in a simple way</Typography>
                <Typography component="h2" variant="h2">Set your name and create your room, invite your team and start estimating</Typography>
                <NameForm onSubmit={OnSubmit} verb={"Create"} buttonDisabled={buttonDisabled} />
                <div className="counter">
                    <Typography variant="body1" className="counter-text">We already did <span className="count">{count ? count : <Skeleton variant="rounded" width={81} height={54} />}</span> poker plannings!</Typography>
                </div>
            </div>
            <div className='right'>
                <Instructions />
            </div>
        </div>
    );
}

export default NewRoom;
