import "./LostConnection.css"

const LostConnection = () => {
  return (
    <div className='lost-connection'>
      <div>Something went wrong =/</div>
      <div>Sorry about that</div>
      <div>Could you please refresh your browser?</div>
    </div>
  )
}

export default LostConnection;
