import ActionsComponent from "../../components/Actions";
import { useSelector, useDispatch } from 'react-redux';
import { useState } from "react";

import { updateVotingSystem } from "../../features/roomReducer";

const Actions = () => {
  const sendMessage = window.sendMessage;
  const roomId = useSelector((state) => state.room.roomId);
  const show = useSelector((state) => state.room.show);
  const votingSystem = useSelector((state) => state.room.votingSystem);
  const youAreAViewer = useSelector((state) => state.room.youAreAViewer);
  const votingSystemOptions = useSelector((state) => state.room.votingSystemOptions);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const dispatch = useDispatch();

  const handleShow = () => {
    sendMessage("toggleVisibility", { roomId });
  }

  const handleClear = () => {
    sendMessage("clear", { roomId });
  }
  
  const [showSnackBar, setShowSnackBar] = useState(false);
  const handleShare = async () => {
    await navigator.clipboard.writeText(window.location.href);
    setShowSnackBar(true);
  }

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
  }

  const handleOpenSettings = () => {
    setSettingsOpen(true);
  }

  const handleCloseSettings = () => {
    setSettingsOpen(false);
  }

  const handleVotingSystem = (event) => {
    dispatch(updateVotingSystem(event.target.value));
    sendMessage("votingSystem", { votingSystem: event.target.value });
  }

  const handleViewer = (event) => {
    sendMessage("viewer");
  }

  return (
    <ActionsComponent 
      handleShow={handleShow} 
      show={show} 
      handleClear={handleClear} 
      handleShare={handleShare} 
      isSettingsOpen={isSettingsOpen} 
      handleOpenSettings={handleOpenSettings}
      handleCloseSettings={handleCloseSettings}
      votingSystem={votingSystem}
      handleVotingSystem={handleVotingSystem}
      showSnackBar={showSnackBar}
      handleCloseSnackBar={handleCloseSnackBar}
      handleViewer={handleViewer}
      youAreAViewer={youAreAViewer}
      votingSystemOptions={votingSystemOptions}
    />
  );
}

export default Actions;
