import NewGuest from "../../components/NewGuest";
import { useSelector, useDispatch } from 'react-redux';
import { setName } from "../../features/roomReducer";
import { useNavigate } from "react-router-dom";
import { gtag } from "../..";
import { useEffect } from "react";

const NewRoom = () => {
    useEffect(() => {
        gtag('event', 'page_view', {
            page_title: 'New User',
            page_path: '/new-user'
        });
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const roomId = useSelector((state) => state.room.roomId);
    async function OnSubmit(name) {
        dispatch(setName(name));
        navigate(`/room/${roomId}`);
    }

    return (
        <>
            <NewGuest onSubmit={OnSubmit} verb="Enter" />
        </>
    );
}

export default NewRoom;
