import UsersVotesComponent from "../../components/UsersVotes";
import { useSelector } from 'react-redux';

const UsersVotes = () => {
    const guests = useSelector((state) => state.room.guests);
    const show = useSelector((state) => state.room.show);

    return (
      <UsersVotesComponent usersVotes={guests} show={show} /> 
    );
  }
  
  export default UsersVotes;
  