import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { cyan } from '@mui/material/colors';

import './TextField.css';

const CssTextField = styled(TextField)({
  '& label': {
    color: cyan[800],
  },
  '& label.Mui-focused': {
    color: 'white',
    transform: 'translate(14px, -16px) scale(0.75)'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: cyan[900],
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: cyan[800],
    },
    '&:hover fieldset': {
      borderColor: cyan[900],
    },
    '&.Mui-focused fieldset': {
      borderColor: cyan[900],
    },
  },
});

export default function CustomizedInputs({label, onChange = () => {}}) {
  return (
    <CssTextField label={label} variant="outlined" onChange={onChange} id="custom-css-outlined-input" />
  );
}