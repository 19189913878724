import CardUi from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PreviewIcon from '@mui/icons-material/Preview';
import CheckIcon from '@mui/icons-material/Check';

import "./UserVote.css";
import ICONS, { ICON_TYPE } from '../../utils/icons';

const UserVote = ({name, vote, voted, show, type, viewer}) => {
    let value = "";
    if (show) {
        if (voted) {
            if (type === ICON_TYPE) {
                value = ICONS[vote];
            } else {
                value = vote;
            }
        } else {
            value = "--";
        }
    } else {
        if (voted) {
            value = <CheckIcon color='white' />
        }
    }
    let className = "vote ";
    if (show) className += "show ";
    else {
        className += "noshow ";
        if (voted) className += "voted";
        else className += "unvoted"
    }

    return (
        <CardUi className={`user ${viewer && "viewer"}`}>
            <Box className="user-name">
                <CardContent> 
                    <Typography component="div">{name}</Typography>
                </CardContent> 
            </Box>
            <Box className="user-vote">
                <CardContent> 
                    {
                        viewer ?
                        <PreviewIcon /> :
                        <div className={className}>{value}</div>
                    }
                </CardContent> 
            </Box>
        </CardUi>
    );
  }
  
  export default UserVote;
  