import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import PreviewIcon from '@mui/icons-material/Preview';

import Snackbar from '../Snackbar';
import "./Actions.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 564,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px'
};

const Actions = ({
    handleShow = ()=>{}, 
    handleClear = ()=>{}, 
    show = false, 
    handleShare = ()=>{}, 
    handleOpenSettings = ()=>{},
    isSettingsOpen = false,
    handleCloseSettings = () => {},
    votingSystem = '',
    handleVotingSystem = () => {},
    handleCloseSnackBar = () => {},
    showSnackBar = false,
    handleViewer = () => {},
    youAreAViewer = false,
    votingSystemOptions = []
}) => {
    return (
        <div className="actions">
            <Tooltip placement="right" title="Settings">
                <IconButton onClick={handleOpenSettings} className='icon'>
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
            <Tooltip placement="right" title="Share">
                <IconButton onClick={handleShare} className='icon'>
                    <ShareIcon/>
                </IconButton>
            </Tooltip>
            <Tooltip placement="right" title={show ? "Hide" : "Show"}>
                <IconButton onClick={handleShow} className='icon'>
                    {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
            </Tooltip>
            <Tooltip placement="right" title="Clear">
                <IconButton onClick={handleClear} className='icon'>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
            <Tooltip placement="right" title="Become a viewer">
                <IconButton onClick={handleViewer} className={`icon ${youAreAViewer && "viewer"}`}>
                    <PreviewIcon />
                </IconButton>
            </Tooltip>
            <div>
                <Modal
                    open={isSettingsOpen}
                    onClose={handleCloseSettings}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="modal"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Settings
                        </Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-label">Voting system</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={votingSystem}
                                label="Voting system"
                                onChange={handleVotingSystem}
                            >
                                {
                                    votingSystemOptions.map(
                                        (system, index) => <MenuItem key={index} value={system.key}>{system.value}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Modal>
            </div>
            <div>
                <Snackbar
                    open={showSnackBar}
                    onClose={handleCloseSnackBar}
                    message="Copied to the clipboard"
                />
            </div>
        </div>
    );
  }
  
  export default Actions;
  