import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'poker',
  initialState: {
    name: localStorage.getItem('name') || "",
    guests: {},
    loading: true,
    selectedCard: "",
    show: false,
    roomId: "",
    lostConnection: false,
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
      localStorage.setItem('name', action.payload);
    },
    setGuests: (state, action) => {
      state.guests = action.payload.guests;
    },
    setRoom: (state, action) => {
      state.roomId = action.payload;
    },
    toggleLoading: (state, action) => {
      state.loading = !state.loading;
    },
    updateRoom: (state, action) => {
      state.guests = action.payload.guests;
      state.show = action.payload.show;
      state.selectedCard = action.payload.yourVote;
      state.hasCoffee = action.payload.hasCoffee;
      state.votingSystem = action.payload.votingSystem;
      state.cards = action.payload.cards;
      state.summary = action.payload.summary;
      state.youAreAViewer = action.payload.youAreAViewer;
      state.votingSystemOptions = action.payload.votingSystemOptions;
    },
    updateVotingSystem: (state, action) => {
      state.votingSystem = action.payload;
    },
    toggleVisibility: (state, action) => {
      state.show = !state.show;
    },
    lostConnection: (state, action) => {
      state.loading = false;
      state.lostConnection = true;
    },
  },
})

export const { setName, lostConnection, setGuests, setRoom, toggleLoading, updateVotingSystem, updateRoom, toggleVisibility } = counterSlice.actions

export default counterSlice.reducer