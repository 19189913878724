import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';

import ICONS, { ICON_TYPE } from '../../utils/icons';
import "./Summary.css"

const Summary = ({ show = false, cards = [], summary = {} }) => {
  let max = 0;
  let total = 0;
  cards.forEach(card => {
    if (summary[card.value] > max) max = summary[card.value];
    total += summary[card.value];
  })

  return (
    <div className='summary'>
      <Typography className="summary-title" variant="h2" component="h2">Summary</Typography>
      <Card sx={{ minWidth: 270 }}>
        <CardContent className='summary-content'>
          { 
            cards.map((card, index) => {
              const percentage = total === 0 ? 0 : Math.round(100 * summary[card.value] / total);
              const percentageText = percentage > 0 ? `${summary[card.value]} (${percentage}%)` : '';
              return (
                <div className='summary-vote' key={index}>
                  <Box className="card-value">
                        <CardContent className="vote">{card.type === ICON_TYPE ? ICONS[card.value] : card.value}</CardContent> 
                  </Box>
                  <div className='summary-bar'>
                    <Typography variant="body2" color="text.secondary" className='qty'>{percentageText}</Typography>
                    <LinearProgress variant="determinate" value={max === 0 ? 0 : 100 * summary[card.value] / max} />
                  </div>
                </div>
            )})
          }
        </CardContent>
      </Card>
    </div>
  )
}

export default Summary;
