import { useSelector } from "react-redux";
import CardsComponent from "../../components/Cards";

const Cards = () => {
    const selectedCard = useSelector((state) => state.room.selectedCard);
    const cards = useSelector((state) => state.room.cards);
    const youAreAViewer = useSelector((state) => state.room.youAreAViewer);

    const sendMessage = window.sendMessage;
    const onClick = ({ type, vote }) => {
      if (youAreAViewer) return;
      sendMessage("vote", { type, vote });
    }

    return (
      <CardsComponent cards={cards} onClick={onClick} selectedCard={selectedCard} youAreAViewer={youAreAViewer} /> 
    );
  }
  
  export default Cards;
  