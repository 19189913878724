import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { cyan } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: cyan[800],
  backgroundColor: "white",
  '&:hover': {
    color: cyan[900],
    backgroundColor: "white",
    fontWeight: '600'
  },
}));

export default function CustomizedButtons({onClick = () => {}, children, className = "", disabled = false}) {
    return (
      <ColorButton className={className} variant="contained" onClick={onClick} disabled={disabled} >{children}</ColorButton>
  );
}