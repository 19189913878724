import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { cyan } from '@mui/material/colors';

import Button from '../Button';
import TextField from '../TextField';
import "./NameForm.css";

const NameForm = ({onSubmit = () => {}, verb = "Enter", buttonDisabled = false}) => {
    const [name, setName] = useState("");

    const handleChange = event => {
        setName(event.target.value);
    }

    const handleSubmit = () => {
        if (!name) return;
        onSubmit(name);
    }

    return (
      <div className="name-form">
        <TextField id="outlined-basic" label="Your name here" variant="outlined"onChange={handleChange} />
        <div className="name-form-submit-container">
            <Button onClick={handleSubmit} disabled={buttonDisabled} >{verb} Room</Button>
            {
                buttonDisabled && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: cyan[800],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }
        </div>
      </div>
    );
}
  
export default NameForm;
  