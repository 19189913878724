import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { gtag } from '../..';
import { useEffect } from 'react';
import "./ContactUs.css";

const ContactUs = () => {
    useEffect(() => {
        gtag('event', 'page_view', {
            page_title: 'Contact Us',
            page_path: '/contact-us'
        });
    });
    return (
        <div className="about">
            <Typography component="h1" variant="h1">
                Contact us
            </Typography>     
            <Card>
                <CardContent className='summary-content'>
                    <Typography component="p" variant="p">
                        For suggestions, doubts or anything else, contact us through <a href="mailto:pokerscrum87@gmail.com">pokerscrum87@gmail.com</a>
                    </Typography>      
                </CardContent>
            </Card>  
        </div>
    );
}

export default ContactUs;
