import Typography from '@mui/material/Typography';
import "./Instructions.css";

const Instructions = () => {
    return (
      <div className='instructions'>
        <Typography component="h3" variant="h3">What is Scrum Poker or Planning Poker?</Typography>
        <Typography component="p" variant="p">It is a technique of estimation for agile methodologies (such as Scrum, Kanban, etc).</Typography>

        <Typography component="h3" variant="h3" className='how-it-works'>How it works?</Typography>
        <Typography component="p" variant="p">For each story or task of a sprint, someone will describe the task and each member of the team will vote how much effort the task needs.</Typography>
        <Typography component="p" variant="p">Usually, people use <a href="https://en.wikipedia.org/wiki/Fibonacci_number" target="_blank" rel="noreferrer">Fibonacci sequence</a> (1, 2, 3, 5, 8, 13, 21, ...) as a representation of effort. <a href="https://fberriman.com/2020/01/22/fruit-salad-a-scrum-estimation-scale/" target="_blank" rel="noreferrer">Here</a>, there is an analogy, between fruits and the sequence, to better understand what means each number and also to make an agreement with the team.</Typography>
      </div>
    );
}
  
export default Instructions;
  