import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

import Donation from "../../components/Donation";
import { gtag } from '../..';
import "./About.css";

const About = () => {
    useEffect(() => {
        gtag('event', 'page_view', {
            page_title: 'About',
            page_path: '/about'
        });
    });

    return (
        <div className="about">
            <Typography component="h1" variant="h1">
                About Scrum Poker and Freddy Brasileiro
            </Typography>
            <Card>
                <CardContent className='summary-content'>
                    <Typography component="p" variant="p">
                        <a href="https://www.linkedin.com/in/freddybrasileiro/" target="_blank" rel="noreferrer">Freddy</a> is a seasoned professional from Brazil with over 15 years of experience in the tech industry. He holds bachelor's and master's degrees in Computer Science, as well as an MBA in People Leadership. Currently, Freddy serves as a Software Engineering Manager at Mercado Libre, the most valuable company in Latin America.
                    </Typography>
                    <Typography component="p" variant="p">
                        Throughout his academic career, Freddy conducted extensive research on ontologies for Computer Science, presenting <a href="https://scholar.google.com/citations?user=3oACh4AAAAAJ&hl=pt-PT" target="_blank" rel="noreferrer">some papers</a> at renowned conferences such as the World Wide Web Conference (WWW) and the International Semantic Web Conference (ISWC). Additionally, Freddy contributed to R&D projects for five years, collaborating with industry giants like Padtec and the Health Sector of the Brazilian Government, where he honed his Java skills. He also taught C and Pascal to various courses as a university professor.
                    </Typography>  
                    <Typography component="p" variant="p">
                        Transitioning into entrepreneurship, Freddy co-founded a company that assisted Globosat, a prominent Brazilian television company, in enhancing their video tagging process. Utilizing ontological techniques, they built relationships between existent tags (which were non-related strings), reducing video tagging time by 50%. This achievement occurs because these relationships tell the system that "Alvin" is a "squirrel," which is a subtype of animal, and they don't need to insert the three tags, which are important to find videos in the future. This experience also marked Freddy's introduction to frontend development using ReactJS.
                    </Typography>  
                    <Typography component="p" variant="p">
                        Subsequently, Freddy joined Guiabolso, a fintech dedicated to improving financial health in Brazil. As a frontend developer, he played a pivotal role in developing Just (a loan platform spinoff of Guiabolso) and Guiabolso websites. His achievements led to a promotion as a Software Engineering Manager, where he led different profiles of developers (backend, frontend, internals, and from outsourcing companies) and facilitated the project of (1) technology transfer of Just to Banco Votorantim and (2) the creation of Guiabolso Connect, a B2B product based on oAuth that enables users to connect their bank accounts for data extraction and sharing.
                    </Typography>  
                    <Typography component="p" variant="p">
                        Currently at Mercado Libre, Freddy is working in the Fintech Business Unit. He has made significant contributions to Payment Link and Checkout products, employing monitoring and Conversion Rate Optimization (CRO) techniques and dealing with business metrics. In his current role at Gateway (a product responsible for all credit/debit card payment processing of the company), he oversees high availability and scalability and leads teams across Brazil, Argentina, and Colombia.  
                    </Typography>  
                    <Typography component="p" variant="p">
                        Then, this Scrum Poker tool was born to help him on daily basis and also as part of his studies about AWS tools (Route 53, DNS, ACM, CloudFront, WebSocket API, Lambda, Dynamo, S3 and IAM).
                    </Typography>
                    <Typography component="p" variant="p">
                        Feel free to colaborate with this initiative 👇🏻 (it's everything running at AWS and it means 💸😅).
                    </Typography>  
                    <Donation />        
                </CardContent>
            </Card>
        </div>
    );
}

export default About;
